.swiper-pagination-bullet {
    background: #CCCCCC;
    width: 20px;
    height: 4px;
    border-radius: 2px;
    position: relative;
    bottom: -14px;
}

.swiper-pagination-bullet-active {
    background: #4480F7;
}